<template>
  <div class="overview-lookalikes d-lg-flex flex-column min-height-100">
    <div class="text-h6 font-weight-bold mb-4">
      {{ computedTitle }}
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            :color="isThemeLight ? 'black' : null"
            v-bind="attrs"
            v-on="on"
          >
            help_outline
          </v-icon>
        </template>
        <span>
          Top followers of this user with more than 1,000 followers.
        </span>
      </v-tooltip>
    </div>

    <v-card class="border-radius-1em box-shadow-soft d-flex flex-column justify-between flex-grow-1">
      <v-card-text class="flex-grow-1">
        <v-list dense two-line>
          <v-list-item
            v-for="item in audienceData.notable.slice(0, 3)"
            :key="item.influencerId"
            @click="redirectToWeb(item.url)"
          >
            <v-list-item-avatar>
              <img :src="proxyUrl(item.imageUrl)" />
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>
                {{ item.displayName }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ nFormatter(item.followers) }} followers,
                {{ nFormatter(item.engagements) }} engagements
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          color="primary"
          text
          small
          @click="shouldShowDialog = true"
        >
          View More
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog
      v-model="shouldShowDialog"
      width="500"
      scrollable
    >
      <v-card>
        <v-card-title>
          {{ computedTitle }}
        </v-card-title>

        <v-card-text class="make-it-scrollable">
          <v-list dense two-line>
            <v-list-item
              v-for="item in audienceData.notable"
              :key="'item-' + item.influencerId"
              @click="redirectToWeb(item.url)"
            >
              <v-list-item-avatar>
                <img :src="proxyUrl(item.imageUrl)" />
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>
                  {{ item.displayName }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ nFormatter(item.followers) }} followers,
                  {{ nFormatter(item.engagements) }} engagements
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            text
            color="primary"
            @click="shouldShowDialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// Export the SFC
export default {
  // Name of the component
  name: "NotableUsers",

  // Accept incoming data from parent
  props: {
    audienceData: {
      type: Object,
      required: true
    },

    selectedTab: {
      type: String,
      required: true
    }
  },

  // Define local data variables
  data: () => ({
    shouldShowDialog: false
  }),

  // Define readonly computable properties
  computed: {
    /**
     * Get the title based on the selected tab
     *
     * @returns {String}
     */
    computedTitle() {
      const map = {
        followers: "Notable Followers",
        likes: "Notable Engagers",
        comments: "Notable Commenters"
      }

      return map[this.selectedTab]
    }
  }
}
</script>
